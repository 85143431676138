import React, { useState } from 'react';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';
import { shape, string } from 'prop-types';
import { StartAJISA } from '@nm-portfolio-lib-web/start-a-jisa';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';

import CustomerHeader from '../Header';
import Footer from '../Footer';

const PortfolioJISADeclaration = ({
  match: {
    params: { uuid }
  },
  ...restProps
}) => {
  const { customerUuid } = useGetAuthenticationMetadata();
  const [hasError, setHasError] = useState(null);
  const DASHBOARD_HOST = global.NutmegConfig.DASHBOARD_HOST;

  return (
    <ScrollToTop>
      <CustomerHeader />
      <StartAJISA.JISADeclarationContainer
        {...restProps}
        customerUuid={customerUuid}
        baseUrl={DASHBOARD_HOST}
        hasError={hasError}
        onError={setHasError}
        potUuid={uuid}
      />
      <Footer />
    </ScrollToTop>
  );
};

PortfolioJISADeclaration.propTypes = {
  match: shape({
    params: shape({
      uuid: string.isRequired
    }).isRequired,
    url: string.isRequired
  }).isRequired
};

export default PortfolioJISADeclaration;
