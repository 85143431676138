import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { NOT_FOUND } from 'http-status-codes';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Section, { stackSpacing } from '@nutkit/component-section';
import { WRAPPER_TYPES as wrapperTypes } from '@nm-pot/common/constants';
import Navigation from '@nutkit/component-navigation';
import { useGetCurrentProducts } from '@nm-pot/common/hooks';
import useGetEligibleProducts from '@nm-portfolio-lib-web/common/hooks/useGetEligibleProducts';
import prismicImageUUIDs from '@nm-portfolio-lib-web/product-select/constants/imagesUuid';
import useGetISAStatus from '@nm-portfolio-lib-web/common/hooks/useGetISAStatus';
import { ISA_STATUS_ERRORS } from '@nm-portfolio-lib-web/common/services/serviceConstants';
import Loading from '@nutkit/component-loading';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';

import { parseSearchParameters } from '../../helpers/parseSearchParameters';

import {
  isTabAvailable,
  getEligibleProducts,
  getTabsSortComparator,
  getSISATabLabelKey,
  isEligible,
  isEligibilityUnknown,
  getTotalByWrapperType,
  getAllTabsTotalValue
} from './helpers';
import { useAvailableDrafts } from './hooks';
import SISATab from './components/SISATab';
import JISATab from './components/JISATab';
import LISATab from './components/LISATab';
import PensionTab from './components/PensionTab';
import ProductSelect from './components/ProductSelect';
import { ProductNavigationItem } from './components/ProductNavigationItem';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';
const ACTIVE_PRODUCT_QUERY_PARAM = 'active-product-tab';
const ALL_TAB_ID = 'all';
const baseProductTabs = [
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.SISA }),
    component: SISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.SISA],
    id: wrapperTypes.SISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.LISA }),
    component: LISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.LISA],
    id: wrapperTypes.LISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.JISA }),
    component: JISATab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.JISA],
    id: wrapperTypes.JISA
  },
  {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: wrapperTypes.PENSION }),
    component: PensionTab,
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET][wrapperTypes.PENSION],
    id: wrapperTypes.PENSION
  }
];

const ProductList = ({ t, userUuid, location, shouldShowLisaTransfersInTab }) => {
  const isDraftPotsFlagEnabled = useFlag(Flags.DASHBOARD_DRAFT_POTS);
  const isAllProductsTabFlagEnabled = useFlag(Flags.ALL_PRODUCTS_TAB);
  const isLISATransferEnabled = useFlag(Flags.LISA_TRANSFERS);
  const { matchesCondition: isLessThanMediumBreakpoint } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { isLoading: productsLoading, error: productsError, getPots, ...allProducts } = useGetCurrentProducts({
    customerUuid: userUuid,
    useAllStatusesInRequest: true
  });
  const products = {
    ...allProducts,
    sisa: { ...allProducts.sisa, data: allProducts.sisa?.data.filter(sisaPotData => sisaPotData.status !== 'SAVED') }
  };
  const { isLoading: draftsLoading, drafts, error: draftsError, getDrafts } = useAvailableDrafts({
    customerUuid: userUuid,
    shouldMakeRequest: isDraftPotsFlagEnabled
  });
  const {
    data: eligibleProductsData,
    error: eligibleProductsError,
    isLoading: eligibleProductsLoading
  } = useGetEligibleProducts({ customerUuid: userUuid, isLISATransferEnabled });
  const { data: isaStatusData, error: isaStatusError, isaStatus, isLoading: isaStatusLoading } = useGetISAStatus({
    customerUuid: userUuid
  });

  if (
    eligibleProductsLoading ||
    (productsLoading && !productsError) ||
    (isaStatusLoading && !isaStatusError) ||
    (draftsLoading && !drafts && !draftsError)
  ) {
    return <Loading data-qa="product-list__loading" />;
  }

  if (productsError || (isaStatusError && isaStatusError !== ISA_STATUS_ERRORS[NOT_FOUND])) {
    return (
      <Notification level={notificationLevels.ERROR} dismissable={false} data-qa="product-list__error-notification">
        {t('dashboard.common.error.defaultText')}
      </Notification>
    );
  }

  const statuses = {
    [wrapperTypes.SISA]: isaStatus,
    [wrapperTypes.LISA]: isaStatusData && isaStatusData.byWrapper && isaStatusData.byWrapper[wrapperTypes.LISA]
  };
  const eligibleProducts = getEligibleProducts(eligibleProductsData, eligibleProductsError);
  const visibleDrafts = isEligibilityUnknown(eligibleProducts) ? undefined : drafts;
  const availableTabs = baseProductTabs
    .filter(item => isTabAvailable(item.id, eligibleProducts, products, statuses))
    .sort(getTabsSortComparator(products, visibleDrafts))
    .map(item => ({
      ...item,
      total: getTotalByWrapperType(products[item.id].data),
      isPresent: products[item.id].isPresent,
      label: t(
        `${TRANSLATION_NAMESPACE}.${
          item.id === wrapperTypes.SISA ? getSISATabLabelKey(eligibleProducts, products, isaStatus) : item.id
        }.label`
      )
    }));
  const allTab = {
    url: Portfolio.getDashboardWithParamsPath({ activeProductTab: ALL_TAB_ID }),
    image: prismicImageUUIDs[window.NutmegConfig.PRISMIC_TARGET].all,
    id: ALL_TAB_ID,
    label: t(`${TRANSLATION_NAMESPACE}.all.label`),
    total: getAllTabsTotalValue(availableTabs),
    isPresent: true
  };

  if (!availableTabs.length) {
    return eligibleProductsError ? (
      <Notification
        level={notificationLevels.ERROR}
        dismissable={false}
        data-qa="product-list__error-notification-eligibility"
      >
        {t('dashboard.common.error.defaultText')}
      </Notification>
    ) : null;
  }

  if (availableTabs.length > 1 && isAllProductsTabFlagEnabled) {
    availableTabs.unshift(allTab);
  }

  const searchTabId = location.search && parseSearchParameters(location.search)[ACTIVE_PRODUCT_QUERY_PARAM];
  const { id: activeTabId, component: ActiveTabComponent } =
    availableTabs.find(item => item.id === searchTabId) || availableTabs[0];

  return (
    <ErrorBoundary
      fallback={
        <Notification level={notificationLevels.ERROR} dismissable={false}>
          {t('dashboard.common.error.defaultText')}
        </Notification>
      }
      contextualDescription="[Dashboard] Product List – Unable to render products"
    >
      {availableTabs.length > 1 &&
        (isLessThanMediumBreakpoint ? (
          <ProductSelect
            data-qa="product-list__navigation-select"
            products={availableTabs}
            selectedProductId={activeTabId}
          />
        ) : (
          <Navigation data-qa="product-list__navigation">
            {availableTabs.map(tab => (
              <ProductNavigationItem
                isActive={tab.id === activeTabId}
                key={tab.label}
                data-qa={`navigation-item__${tab.id}`}
                {...tab}
              />
            ))}
          </Navigation>
        ))}
      <Section data-qa={`product-list__${activeTabId}`}>
        {activeTabId === ALL_TAB_ID ? (
          availableTabs
            .filter(tab => tab.id !== ALL_TAB_ID)
            .map(({ component: ProductComponent, id }) => (
              <Section stackSpacing={stackSpacing.LG} key={id}>
                <ProductComponent
                  pots={products[id].data}
                  isPresent={products[id].isPresent}
                  drafts={visibleDrafts && visibleDrafts[id] && visibleDrafts[id].data}
                  isDraftPresent={visibleDrafts && visibleDrafts[id] && visibleDrafts[id].isPresent}
                  updatePots={getPots}
                  updateDrafts={getDrafts}
                  userUuid={userUuid}
                  eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
                  isEligible={isEligible(eligibleProducts, id)}
                  {...(id === wrapperTypes.SISA
                    ? {
                        eligibleSISA: isEligible(eligibleProducts, wrapperTypes.SISA),
                        eligibleGIA: isEligible(eligibleProducts, wrapperTypes.GIA),
                        isaStatus,
                        tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                      }
                    : {})}
                  withHeading
                  shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
                />
              </Section>
            ))
        ) : (
          <ActiveTabComponent
            pots={products[activeTabId].data}
            isPresent={products[activeTabId].isPresent}
            drafts={visibleDrafts && visibleDrafts[activeTabId] && visibleDrafts[activeTabId].data}
            isDraftPresent={visibleDrafts && visibleDrafts[activeTabId] && visibleDrafts[activeTabId].isPresent}
            updatePots={getPots}
            updateDrafts={getDrafts}
            userUuid={userUuid}
            eligibilityUnknown={isEligibilityUnknown(eligibleProducts)}
            isEligible={isEligible(eligibleProducts, activeTabId)}
            {...(activeTabId === wrapperTypes.SISA
              ? {
                  eligibleSISA: isEligible(eligibleProducts, wrapperTypes.SISA),
                  eligibleGIA: isEligible(eligibleProducts, wrapperTypes.GIA),
                  isaStatus,
                  tabLabelKey: getSISATabLabelKey(eligibleProducts, products, isaStatus)
                }
              : {})}
            shouldShowLisaTransfersInTab={shouldShowLisaTransfersInTab}
          />
        )}
      </Section>
    </ErrorBoundary>
  );
};

ProductList.propTypes = {
  t: func.isRequired,
  userUuid: string.isRequired,
  location: shape({
    search: string
  }).isRequired,
  shouldShowLisaTransfersInTab: bool.isRequired
};

export default ProductList;
