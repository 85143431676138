import React from 'react';
import { string, shape, func } from 'prop-types';
import { Container, Row, Col } from '@nutkit/component-grid';
import { Portfolio } from '@nm-utils-lib-web/routes';
import { Heading } from '@nutkit/component-text';
import TransactionHistoryContainer, { investmentActivityColumns } from '@nm-payments/transaction-history';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import Icon, { iconSizes, IconChevronLeft } from '@nutkit/component-icon';
import Link, { linkSizes } from '@nutkit/component-link';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';

import CustomerHeader from '../Header';
import Footer from '../Footer';

const TRANSLATION_NAMESPACE = 'dashboard.transactionsHistory';
const COMMON_TRANSLATION_NAMESPACE = 'dashboard.common';

const TransactionHistoryJISA = ({
  match: {
    params: { childUserUuid, wrapperType }
  },
  location,
  t
}) => {
  const isPensionWrapper = wrapperType === WRAPPER_TYPES.PENSION;
  const hideColumns = isPensionWrapper ? [investmentActivityColumns.ACCOUNT] : null;

  return (
    <>
      <CustomerHeader />
      <Container>
        <Row>
          <Col>
            <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.XL}>
              <Heading noStack>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
              <Link
                data-qa="dashboard__back-to-jisa-dashboard"
                href={Portfolio.getDashboardWithParamsPath({ activeProductTab: WRAPPER_TYPES.JISA })}
                size={linkSizes.SM}
              >
                <Icon component={IconChevronLeft} size={iconSizes.SM} />
                {t(`${COMMON_TRANSLATION_NAMESPACE}.link.backToDashboard.text`)}
              </Link>
            </Section>
            <TransactionHistoryContainer
              fields={{ generalHeading: t(`${TRANSLATION_NAMESPACE}.jisa.generalHeading`) }}
              customerUuid={childUserUuid}
              location={location}
              match={{ url: `${Portfolio.TRANSACTION_HISTORY_JISA_PATH}/${childUserUuid}` }}
              hideColumns={hideColumns}
              withDownloadEnabled
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

TransactionHistoryJISA.propTypes = {
  match: shape({
    params: shape({
      wrapperType: string,
      childUserUuid: string
    }).isRequired
  }).isRequired,
  location: shape({
    pathname: string
  }).isRequired,
  t: func.isRequired
};

export default TransactionHistoryJISA;
