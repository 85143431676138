import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from '@nm-utils-lib-web/flags';
import { monitor, apps, getAppName } from '@nm-utils-lib-web/monitoring';
import { AuthProvider } from '@nm-utils-lib-web/authentication';
import Loading from '@nutkit/component-loading';
import { SWRConfig } from 'swr';
import '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/App';
import './helpers/i18n';

const appName = apps.NM_DASHBOARD_WEB;
const environment = window.NutmegConfig.ENVIRONMENT;
const isLocalEnvironment = environment === 'local';

monitor.init({
  app: getAppName(appName),
  disabled: isLocalEnvironment,
  environment,
  tracingSampleRate: parseFloat(window.NutmegConfig.SENTRY_SAMPLE_RATE),
  ...(!isLocalEnvironment && {
    release: `${appName}@${window.NutmegConfig.VERSION}`
  })
});

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <SWRConfig value={{ revalidateIfStale: false }}>
      <AuthProvider renderBlocking>
        <FlagsProvider>
          <Router>
            <App />
          </Router>
        </FlagsProvider>
      </AuthProvider>
    </SWRConfig>
  </Suspense>,
  document.getElementById('root')
);
