import React from 'react';
import { string, shape, func } from 'prop-types';
import { Container, Row, Col } from '@nutkit/component-grid';
import { Heading } from '@nutkit/component-text';
import TransactionHistoryContainer, { investmentActivityColumns } from '@nm-payments/transaction-history';
import { Portfolio } from '@nm-utils-lib-web/routes';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import Icon, { iconSizes, IconChevronLeft } from '@nutkit/component-icon';
import Link, { linkSizes } from '@nutkit/component-link';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';

import LayoutWithSideMenu from '../../common/LayoutWithSideMenu';

const wrapperTypes = {
  PENSION: 'pension'
};
const TRANSLATION_NAMESPACE = 'dashboard.transactionsHistory';
const COMMON_TRANSLATION_NAMESPACE = 'dashboard.common';

const TransactionHistory = ({
  match: {
    params: { wrapperType, potUuid }
  },
  location,
  t
}) => {
  const isPensionWrapper = wrapperType === wrapperTypes.PENSION;
  const hideColumnsProps = isPensionWrapper && { hideColumns: [investmentActivityColumns.ACCOUNT] };
  const { customerUuid } = useGetAuthenticationMetadata();

  return (
    <>
      <LayoutWithSideMenu data-qa="transaction-history">
        {() => (
          <Container>
            <Row>
              <Col>
                <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.XL}>
                  <Heading noStack>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
                  <Link
                    data-qa="dashboard__back-to-portfolio"
                    href={Portfolio.DASHBOARD_PATH}
                    size={linkSizes.SM}
                    isInternal
                  >
                    <Icon component={IconChevronLeft} size={iconSizes.SM} />
                    {t(`${COMMON_TRANSLATION_NAMESPACE}.link.backToPortfolio.text`)}
                  </Link>
                </Section>
                <TransactionHistoryContainer
                  customerUuid={customerUuid}
                  potUuid={potUuid}
                  location={location}
                  match={{ url: Portfolio.TRANSACTION_HISTORY_PATH }}
                  withDownloadEnabled
                  {...hideColumnsProps}
                />
              </Col>
            </Row>
          </Container>
        )}
      </LayoutWithSideMenu>
    </>
  );
};

TransactionHistory.propTypes = {
  match: shape({
    params: shape({
      wrapperType: string,
      potUuid: string
    }),
    url: string
  }),
  location: shape({
    pathname: string
  }).isRequired,
  t: func.isRequired
};

TransactionHistory.defaultProps = {
  match: {}
};

export default TransactionHistory;
