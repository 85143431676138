import React from 'react';
import { shape, string } from 'prop-types';
import { ISASummary } from '@nm-portfolio-lib-web/isa-summary';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import PromptCard from '../PromptCard';

const PortfolioDashboardSidebar = ({ userDetails }) => {
  const dashboardPromptCardFlag = useFlag(Flags.DASHBOARD_PROMPT_CARD);
  const jlDashboardPromptCardFlag = useFlag(Flags.JL_DASHBOARD_PROMPT_CARD);
  const shouldShowPromptCard = isJohnLewisFinanceGBR() ? jlDashboardPromptCardFlag : dashboardPromptCardFlag;

  return (
    <>
      {shouldShowPromptCard ? (
        <PromptCard
          data-qa="prompt-card"
          interactionPoint={window.NutmegConfig.DASHBOARD_PROMPT_CARDS_INTERACTION_POINT}
          userUuid={userDetails.userUuid}
        />
      ) : null}
      <ISASummary customerUuid={userDetails.userUuid} />
    </>
  );
};

PortfolioDashboardSidebar.propTypes = {
  userDetails: shape({
    userUuid: string
  }).isRequired
};

export default PortfolioDashboardSidebar;
