import React from 'react';
import { shape, string } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from '@nutkit/component-grid';
import useGetPots from '@nm-pot/common/hooks/useGetPots';
import { POT_STATUSES } from '@nm-pot/common/constants';
import { Payment } from '@nm-utils-lib-web/routes';
import { ScrollToTop } from '@nm-utils-lib-web/scroll-to';

import ErrorPage from '../ErrorPage';
import CustomerHeader from '../Header';
import Footer from '../Footer';

import {
  PAY_IN_NOW_SUBPATH,
  MONTHLY_PAYMENTS_SUBPATH,
  NEW_POT_PAYMENT_PATH,
  TRANSFER_BETWEEN_POTS_SUBPATH
} from './constants/PaymentsAndTransfersPaths';

const PaymentsAndTransfers = ({ match: { path }, userDetails }) => {
  const { data: potItems, isValidating } = useGetPots({
    customerUuid: userDetails.userUuid,
    statuses: [POT_STATUSES.ACTIVE]
  });
  const pots = {
    isLoaded: potItems && !isValidating,
    potItems
  };

  if (!pots.isLoaded) {
    return null;
  }

  return (
    <ScrollToTop>
      <CustomerHeader />
      <Container>
        <Row>
          <Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
            <Switch>
              <Route
                exact
                path={`${path}/${PAY_IN_NOW_SUBPATH}`}
                data-qa="pay-in-now-route"
                render={props => {
                  const potId = props?.match?.params?.potUuid;

                  if (potId) {
                    window.location.replace(Payment.paymentPayInNowPath(potId));

                    return null;
                  }

                  window.location.replace(Payment.paymentPayInNowPath());

                  return null;
                }}
              />
              <Route
                exact
                path={`${path}/${MONTHLY_PAYMENTS_SUBPATH}`}
                data-qa="monthly-payments-route"
                render={props => {
                  const potUuid = props?.match?.params?.potUuid;

                  if (potUuid) {
                    global.location.assign(Payment.paymentsMonthlyPaymentsPath(potUuid));

                    return null;
                  }
                  global.location.assign(Payment.paymentsMonthlyPaymentsListPath());

                  return null;
                }}
              />
              <Route
                exact
                path={`${path}/${TRANSFER_BETWEEN_POTS_SUBPATH}`}
                data-qa="transfer-between-pots-route"
                render={props => {
                  window.location.replace(Payment.transferBetweenPotsPath(props.match.params.defaultTab));

                  return null;
                }}
              />
              <Route
                exact
                path={`${path}/${NEW_POT_PAYMENT_PATH}`}
                data-qa="new-pot-payment-route"
                render={props => {
                  const potUuid = props?.match?.params?.potUuid;

                  if (potUuid) {
                    global.location.assign(
                      `${Payment.paymentsCombinedPaymentPath({ potUuid })}${global.location.search}`
                    );
                  }

                  return null;
                }}
              />
              <Route component={ErrorPage} data-qa="error-page-route" />
            </Switch>
          </Col>
        </Row>
      </Container>
      <Footer />
    </ScrollToTop>
  );
};

PaymentsAndTransfers.propTypes = {
  match: shape({
    path: string
  }),
  userDetails: shape({
    userUuid: string
  }).isRequired
};

PaymentsAndTransfers.defaultProps = {
  match: {
    path: null
  }
};

export default PaymentsAndTransfers;
