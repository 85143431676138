import React from 'react';
import { Heading } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import { TransferSubmitted } from '@nm-portfolio-lib-web/common/components';

const TRANSLATION_NAMESPACE = 'start.transferSubmittedSISA';

const TransferSubmittedSISA = props => {
  const { t, 'data-qa': dataQa, journeyNavigation } = props;
  const mainTitle = t('start.transferFormSISA.title');
  const headingText = t(`${TRANSLATION_NAMESPACE}.headingText`);
  const expectationText = t(`${TRANSLATION_NAMESPACE}.expectationText`);
  const reassuranceText = t(`${TRANSLATION_NAMESPACE}.reassuranceText`);
  const journeyNavigationControls = {
    ...journeyNavigation,
    continueButton: { ...journeyNavigation.continueButton, label: t('common.controls.goToDashboard') },
    backButton: { ...journeyNavigation.backButton, label: t('common.controls.transferAnother') }
  };
  const commonProps = {
    headingText,
    expectationText,
    reassuranceText,
    journeyNavigation: journeyNavigationControls,
    dataQa,
    translationNamespace: TRANSLATION_NAMESPACE
  };

  return (
    <>
      <Heading>{mainTitle}</Heading>
      <Panel>
        <TransferSubmitted {...commonProps} />
      </Panel>
    </>
  );
};

TransferSubmittedSISA.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  t: func.isRequired,
  'data-qa': string
};

TransferSubmittedSISA.defaultProps = {
  'data-qa': 'transfer-submitted-sisa'
};

export default TransferSubmittedSISA;
