import React from 'react';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { TransferDownloadForm, JourneyNavigation } from '@nm-portfolio-lib-web/common/components';
import { PARTIAL_TRANSFER_PDF, getManualPdfPath } from '@nm-portfolio-lib-web/common/constants';
import { TRANSFER_OPTIONS } from '@nm-portfolio-lib-web/common/constants/TransferOptions';

import { TRANSFER_STATUS as STATUS } from '../../constants/status';

const TRANSLATION_NAMESPACE = 'start.transferDownloadFormSISA';
const isManualStatus = data => data && data.status === STATUS.MANUAL;

const TransferDownloadFormSISA = ({
  t,
  'data-qa': dataQa,
  transferData,
  journeyNavigation,
  userUuid,
  onboarding,
  openISA,
  transferType
}) => {
  const mainTitle = t('start.transferFormSISA.title');
  const transferAnotherLabel = t('common.controls.transferAnother');
  const commonContent = <Text>{t('common.downloadTransferForm.additionalText')}</Text>;
  const partialTransferTitle = t(`${TRANSLATION_NAMESPACE}.partialTransferTitle`);
  const manualProviderTitle = t(`${TRANSLATION_NAMESPACE}.manualProviderTitle`);
  const manualProviderContent = (
    <>
      <Text>{t(`${TRANSLATION_NAMESPACE}.manualProviderText`)}</Text>
      {commonContent}
    </>
  );
  const title = isManualStatus(transferData) ? manualProviderTitle : partialTransferTitle;
  const content = isManualStatus(transferData) ? manualProviderContent : commonContent;
  const fileURL = isManualStatus(transferData) ? getManualPdfPath(userUuid, transferData) : PARTIAL_TRANSFER_PDF;
  const journeyNavigationProps = {
    ...journeyNavigation,
    backButton:
      openISA && transferType === TRANSFER_OPTIONS.PREVIOUS_YEAR
        ? null
        : !openISA && transferType === TRANSFER_OPTIONS.PREVIOUS_YEAR
        ? journeyNavigation.backButton
        : { ...journeyNavigation.backButton, label: transferAnotherLabel }
  };

  return (
    <>
      <Heading>{mainTitle}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{title}</Heading>
        <TransferDownloadForm data-qa={dataQa} additionalContent={content} pdfUrl={fileURL} onboarding={onboarding} />
        <JourneyNavigation {...journeyNavigationProps} />
      </Panel>
    </>
  );
};

TransferDownloadFormSISA.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  userUuid: string.isRequired,
  t: func.isRequired,
  transferData: shape({
    fileId: string,
    data: string,
    status: string
  }),
  onboarding: string,
  openISA: bool,
  transferType: string,
  'data-qa': string
};

TransferDownloadFormSISA.defaultProps = {
  'data-qa': 'transfer-sisa-form',
  transferData: null,
  openISA: null,
  onboarding: '',
  transferType: null
};

export default TransferDownloadFormSISA;
