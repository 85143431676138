import React from 'react';
import { string } from 'prop-types';
import Notification, { notificationLevels } from '@nutkit/component-notification';

const ErrorPage = ({ status, message }) => (
  <div data-qa="error-page">
    <Notification level={notificationLevels.ERROR} dismissable={false}>
      {status ? `${status} : ${message}` : message}
    </Notification>
  </div>
);

ErrorPage.propTypes = {
  status: string,
  message: string
};

ErrorPage.defaultProps = {
  status: '404',
  message: 'Page not found'
};

export default ErrorPage;
