import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useFlag, Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';
import { Logout, featureDetection } from '@nm-utils-lib-web/authentication';
import { useMaintenanceMode } from '@nm-utils-lib-web/maintenance';
import { Authentication, Login, Customer, Payment, Portfolio } from '@nm-utils-lib-web/routes';
import { WRAPPER_TYPES, JOURNEY_TYPES } from '@nm-pot/common/constants';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useInitialiseAnalytics } from '@nm-utils-lib-web/analytics';
import { apps } from '@nm-utils-lib-web/monitoring';
import Loading from '@nutkit/component-loading';

import NoMatch404 from '../NoMatch404';
import { MetaTitleProtectedRoute } from '../MetaTitleProtectedRoute/MetaTitleProtectedRoute';
import MetaTitleRoute from '../MetaTitleRoute';

export const Dashboard = lazy(() => import('../Dashboard'));
export const PaymentsAndTransfers = lazy(() => import('../PaymentsAndTransfers'));
export const ISADetails = lazy(() => import('../ISADetails'));
export const PortfolioDetailsContainer = lazy(() => import('../PortfolioDetailsContainer'));
export const PortfolioJISADeclaration = lazy(() => import('../PortfolioJISADeclaration'));
export const TransactionHistory = lazy(() => import('../TransactionHistory'));
export const TransactionHistoryJISA = lazy(() => import('../TransactionHistoryJISA'));
export const CreateOrTransferJISA = lazy(() => import('../CreateOrTransferJISA'));
export const StartOrTransferSISA = lazy(() => import('../StartOrTransferSISA'));
export const PortfolioDashboard = lazy(() => import('../PortfolioDashboard'));
export const ProductSelection = lazy(() => import('../ProductSelection'));

const App = () => {
  const { t } = useTranslation();
  const isMaintenanceModeFlagEnabled = useFlag(Flags.MAINTENANCE_MODE);
  const isLISATransferEnabled = useFlag(Flags.LISA_TRANSFERS);
  const wealthServicesCtaVariant = useVariant(Flags.WEALTH_SERVICES_CTA_TEST);
  const shouldShowWealthServicesBanner =
    wealthServicesCtaVariant.enabled && wealthServicesCtaVariant.name === Variants.VARIANT_F;

  useInitialiseAnalytics({ appName: apps.NM_DASHBOARD_WEB });
  useMaintenanceMode(isMaintenanceModeFlagEnabled);

  return (
    <>
      <Helmet>
        <title>{t('global.common.organisation.appTitle')}</title>
      </Helmet>
      <Switch>
        <MetaTitleProtectedRoute
          data-qa="withdraw-redirect-route"
          path="/withdraw"
          render={() => {
            global.location.assign('/payment/withdraw');

            return null;
          }}
        />
        <MetaTitleProtectedRoute
          path={Portfolio.JISA_OPEN_TRANSFER_PATH}
          render={() => <CreateOrTransferJISA />}
          data-qa="create-or-transfer-jisa-route"
        />
        <MetaTitleProtectedRoute
          path={Portfolio.PORTFOLIO_DETAILS_PATH}
          render={() => <PortfolioDetailsContainer />}
          data-qa="portfolio-details"
          titleTranslationKey="dashboard.common.metaTitles.overallPortfolioValue"
        />
        <MetaTitleProtectedRoute
          path={Portfolio.getDeclarationPath({
            wrapperType: WRAPPER_TYPES.JISA,
            potUuid: ':uuid',
            journeyType: JOURNEY_TYPES.CREATE_POT
          })}
          render={() => <PortfolioJISADeclaration />}
          data-qa="portfolio-jisa-declaration-route"
        />
        <MetaTitleProtectedRoute
          data-qa="payment-and-transfers-route"
          path="/payments-and-transfers"
          render={() => <PaymentsAndTransfers />}
        />
        <MetaTitleProtectedRoute
          data-qa="new-pot-bank-redirect-route"
          path={Payment.NEW_POT_BANK_TRANSFER_STATUS}
          render={() => {
            global.location.assign(`${Payment.INITIAL_BANK_TRANSFER_STATUS}${global.location.search}`);

            return null;
          }}
        />
        <MetaTitleProtectedRoute data-qa="isa-details-route" path="/isa-details" render={() => <ISADetails />} />
        <MetaTitleProtectedRoute
          data-qa="transaction-hist-jisa-route"
          path={`${Portfolio.TRANSACTION_HISTORY_JISA_PATH}/:childUserUuid/:wrapperType?`}
          render={() => <TransactionHistoryJISA />}
        />
        <MetaTitleProtectedRoute
          data-qa="transaction-hist-route"
          path={`${Portfolio.TRANSACTION_HISTORY_PATH}/:wrapperType/:potUuid?`}
          render={() => <TransactionHistory />}
        />
        <MetaTitleProtectedRoute
          data-qa="start-or-transfer-sisa-route"
          path="/sisa"
          render={() => <StartOrTransferSISA />}
        />
        <MetaTitleProtectedRoute
          data-qa="product-select-route"
          path={Portfolio.PRODUCT_SELECTION_PATH}
          render={() => (
            <ProductSelection
              shouldShowWealthServicesBanner={shouldShowWealthServicesBanner}
              isLISATransferEnabled={isLISATransferEnabled}
            />
          )}
        />
        <MetaTitleProtectedRoute
          titleTranslationKey="dashboard.common.metaTitles.dashboard"
          path={Portfolio.DASHBOARD_PATH}
          exact
          render={() => <PortfolioDashboard />}
          data-qa="portfolio-dashboard-route"
        />
        <MetaTitleProtectedRoute
          exact
          data-qa="customer_auth_callback_route"
          path={Authentication.CUSTOMER_CALLBACK_PATH}
          render={() => <Loading />}
        />
        <MetaTitleProtectedRoute
          exact
          data-qa="impersonation_auth_callback_route"
          path={Authentication.IMPERSONATION_CALLBACK_PATH}
          render={() => <Loading />}
        />
        <Route
          exact
          data-qa="customer_auth_logout_route"
          path={Login.LOGOUT_PATH}
          render={() => <Logout singleLogoutCookieId={featureDetection.DASHBOARD_SLO_COOKIE} />}
        />
        <Redirect from="/annual-review/*" to={Customer.CUSTOMER_ANNUAL_REVIEW_DETAILS_PATH} />
        <MetaTitleRoute titleTranslationKey="dashboard.common.metaTitles.notFound" component={NoMatch404} />
      </Switch>
    </>
  );
};

export default App;
