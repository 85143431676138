import React from 'react';
import BaseLayoutWithSideMenu from '@nm-ui-shared-lib-web/layout-with-side-menu';
import { APP_IDS, MENU_ITEM_IDS } from '@nm-ui-shared-lib-web/side-menu';

export const LayoutWithSideMenu = props => (
  <BaseLayoutWithSideMenu
    appId={APP_IDS.DASHBOARD}
    internalLinkIds={[MENU_ITEM_IDS.TRANSACTIONS_ISA, MENU_ITEM_IDS.TRANSACTIONS_PENSION, MENU_ITEM_IDS.DASHBOARD]}
    {...props}
  />
);
